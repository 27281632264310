import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useUsers() {
  const permissions = ref([]);
  const meta = ref([]);
  const permission = ref();
  const router = useRouter();

  const getPermissions = async (page) => {
    let response = await ApiService.query("permissions", page);
    permissions.value = response.data.data;
    meta.value = response.data.meta;
  };

  const getPermission = async (id) => {
    let response = await ApiService.get("permissions/" + id);

    permission.value = response.data.data;
  };

  const destroyPermission = async (id) => {
    await ApiService.delete("permissions/" + id);
  };

  const stoerPermission = async (data) => {
    await ApiService.post("permissions", data);
    await router.push({ name: "settings.permissions.index" });
  };

  const updatePermission = async (data) => {
    await ApiService.put("permissions/" + data.id, data);
    await router.push({
      name: "settings.permissions.edit",
      params: { id: data.id },
    });
  };

  return {
    permissions,
    meta,
    getPermissions,
    getPermission,
    permission,
    destroyPermission,
    stoerPermission,
    updatePermission,
  };
}
